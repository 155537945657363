<template>
  <div class="stat">
    <div class="stat__tabs">
      <button
        v-for="(tab, index) of tabs"
        :key="index"
        @click="onSwitchTab(tab.status, tab.refund_status)"
        class="stat__tab"
        :class="{'stat__tab--active': current_status === tab.status && current_refund_status === tab.refund_status}"
      >
        {{ tab.name }}
      </button>
    </div>
    <template v-if="refundList.length">
      <app-table-head
        @update-limiter="onUpdateLimiter"
        :count="refundList.length"
        :page="pagination.page"
        :all="pagination.count"
      />
      <div class="expired__list">
        <div v-for="booking in refundList" :key="booking.id" class="expired__item">
          <div class="expired__row">
            <div class="expired__content">
              <div class="expired__line">
                <div class="expired__key-value">
                  <span>Гость: </span>
                  <router-link
                    v-if="booking.guest"
                    :to="{ name: 'guest-detail', params: { id: booking.guest.id } }"
                    class="expired__link"
                  >
                    {{ booking.guest.name }}
                  </router-link>
                  <span v-else>-</span>
                </div>
                <div class="expired__key-value">
                  <span>Телефон: </span>
                  <span>{{ booking.guest ? booking.guest.phone : '-' }}</span>
                </div>
                <div class="expired__key-value">
                  <span>Кол-во гостей: </span>
                  <span>{{ booking.count_guests }} (cтол: {{ booking.table }})</span>
                </div>
              </div>
              <div class="expired__line">
                <div class="expired__key-value">
                  <span>ID бронирования: </span>
                  <router-link
                    :to="{ name: 'booking-detail', params: { id: booking.id } }"
                    class="expired__link"
                  >
                    {{ booking.id }}
                  </router-link>
                </div>
                <div class="expired__key-value">
                  <span>Дата визита: </span>
                  <span>{{ formatDate(booking.time_start) }} - {{ booking.time_end.slice(11, 16) }}</span>
                </div>
                <div class="expired__key-value">
                  <template v-if="booking.time_cancel">
                    <span>Дата отмены: </span>
                    <span>{{ formatDate(booking.time_cancel) }}</span>
                  </template>
                  <template v-else-if="booking.time_close">
                    <span>Дата закрытия: </span>
                    <span>{{ formatDate(booking.time_close) }}</span>
                  </template>
                  <template v-else>
                    <span>Дата отмены / закрытия: </span>
                    <span>-</span>
                  </template>
                </div>
              </div>
              <div class="expired__line">
                <div class="expired__key-value">
                  <span>Оплачено: </span>
                  <span>{{ new Intl.NumberFormat('ru-RU').format(booking.deposit_amount) }}&nbsp;₽</span>
                </div>
                <div class="expired__key-value">
                  <span>Возвращено: </span>
                  <span>{{ new Intl.NumberFormat('ru-RU').format(booking.refund_amount) }}&nbsp;₽</span>
                </div>
                <div class="expired__key-value">
                  <span>Баланс: </span>
                  <span>{{ new Intl.NumberFormat('ru-RU').format(booking.balance) }}&nbsp;₽</span>
                </div>
              </div>
              <div v-if="booking.refund_status.id === 'required' || booking.refund_status.id === 'completed'" class="expired__line">
                <template>
                  <div class="expired__key-value">
                    <span>Запрашиваемый возврат: </span>
                    <span>{{ new Intl.NumberFormat('ru-RU').format(booking.refund_request_amount) }}&nbsp;₽</span>
                  </div>
                  <div class="expired__key-value">
                    <span>Дата запроса: </span>
                    <span>{{ formatDate(booking.time_refund_request) }}</span>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="current_status === '' && current_refund_status === 'required'" class="expired_btns">
              <app-button @click="onShowModal(booking.id)" size="small" theme="error">Завершить</app-button>
            </div>
          </div>
          <div v-if="booking.comment_hostes" class="expired__row">
            <div class="expired__key-value"><span>Комментарий хостес: </span><span>{{ booking.comment_hostes }}</span></div>
          </div>
        </div>
      </div>
      <paginate
        v-if="pagination.pages > 1"
        v-model="pagination.page"
        :page-count="pagination.pages"
        :clickHandler="onClickPagination"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination'"
        :page-class="'pagination__item'"
        :page-link-class="'pagination__link'"
        :prev-class="'pagination__item pagination__item--prev'"
        :next-class="'pagination__item pagination__item--next'"
        :next-link-class="'pagination__link pagination__link--next'"
        :prev-link-class="'pagination__link pagination__link--prev'"
      />
    </template>
    <div v-else class="logs-plug">
      <img src="@/assets/img/logs-empty-plug.svg" alt="">
      <div class="logs-plug__text">Запросы отсутствуют</div>
    </div>
    <modal
      name="accept-modal"
      :width="620"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="true"
    >
      <div class="modal">
        <div slot="top-right">
          <button @click="$modal.hide('accept-modal')" class="modal__close"></button>
        </div>
        <div class="modal__title  modal__title--center">Зафиксировать возврат?</div>
        <app-cells position="center">
          <div class="modal__body modal__body--center modal__body--text-center">
            Вы устанавливаете отметку о совершении возврата в системе CloudPayments. Информация о возврате в системе
            появится в течение 24-х часов, но мы уже перенесем бронирование в список "Завершены".
          </div>
        </app-cells>
        <app-cells position="center">
          <app-button
            @click="onActionBooking"
            size="normal"
            type="button"
            ref="accept"
          >
            Подтвердить действие
          </app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { getRefundList, patchBooking } from '@/http'
import { dateFormatting } from '@/helpers'

export default {
  name: 'Refund',
  data() {
    return {
      refundList: [],
      current_status: '',
      current_refund_status: 'required',
      current_booking: null,
      pagination: {
        pages: 1,
        page: 1,
        count: 1,
      },
      table_limit: 20,
      tabs: [
        {
          name: 'Требуется возврат',
          status: '',
          refund_status: 'required',
        },
        {
          name: 'Не требуется (Закрыты)',
          status: 'close',
          refund_status: 'not_required',
        },
        {
          name: 'Не требуется (Отменены)',
          status: 'cancel',
          refund_status: 'not_required',
        },
        {
          name: 'Завершены',
          status: '',
          refund_status: 'completed',
        },
      ],
    }
  },
  created() {
    this.getStatistic()
  },
  methods: {
    getStatistic() {
      const query = {
        status: this.current_status,
        refund_status: this.current_refund_status,
        page: this.pagination.page,
        page_size: this.table_limit,
      }
      getRefundList(query)
        .then(response => {
          this.refundList = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onSwitchTab(status, refund_status) {
      this.current_status = status
      this.current_refund_status = refund_status
      this.pagination.page = 1
      this.table_limit = 20
      this.getStatistic()
    },
    onShowModal(id) {
      this.current_booking = id
      this.$modal.show('accept-modal')
    },
    formatDate(date) {
      return dateFormatting(date, 'iso-to-normal')
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.getStatistic()
    },
    onClickPagination() {
      this.getStatistic()
    },
    onActionBooking() {
      patchBooking(this.current_booking, { refund_status: 'completed' })
        .then(() => {
          this.getStatistic()
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Возврат зафиксирован'
          })
        })
        .finally(() => {
          this.$refs.accept.preload = false
          this.$modal.hide('accept-modal')
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.logs-plug
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 20px
  padding: 50px 10px

.logs-plug__text
  color: #737c85
  font-weight: 600
</style>
